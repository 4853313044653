import { Container, Box, Flex, Link, Button } from "@chakra-ui/react";
import { useGetCities } from "hooks/useCities";
import XHome from 'routes/XHome';

export default function XCareers(props) {
  const { siteData } = props;
  const bannerURL =
    "https://xcdn.x0pa.ai/xfe/rsm/rsm-careers-page-header.jpg";
  return (
    <Box>
      <Box
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        style={{ height: 300 }}
        backgroundImage={bannerURL}
      ></Box>
      <XHome careersWebSite  siteData={siteData} />
    </Box>
  );
}
