import React from "react";
import { Outlet } from "react-router-dom";
import { Box, Flex, Container, Link } from "@chakra-ui/react";

export default function XCareers(props) {
  const { siteData } = props || {};
  const { sourceName } = siteData || {};
  const externalMenus = [
    {
      name: "About Us",
      key: "/about",
      value:
        "https://rsmstoneforest.sg/accountstaff",
      xtarget: "_blank",
    },
    {
      name: "Contact Us",
      key: "/contact",
      xtarget: "_blank",
      value: "https://rsmstoneforest.sg/contact-us",
    },
    {
      name: "Submit CV",
      key: "/submit-cv",
      xtarget: "_self",
      value: "/careers/c/r/tp?xpid=accountstaff&xsn=accountstaff&xlng=en",
    },
  ];
  const footerLinks = [
    {
      name: "Facebook",
      key: "/facebook",
      xtarget: "_blank",
      value: "https://www.facebook.com/StoneForestSingapore/",
    },
    {
      name: "LinkedIn",
      key: "/linkedin",
      xtarget: "_blank",
      value: "https://sg.linkedin.com/company/stoneforest",
    },
    {
      name: "Privacy",
      key: "/about",
      xtarget: "_blank",
      value: "https://www.stoneforest.com.sg/privacy-statement",
    },
  ];
  let xlogo = window.xfhname || 'x';
  console.log('CLAYOUT', siteData, window, window.xfhname);
  return (
    <Box>
      <Container as="nav" maxW="1200px" className="xcs-cs-topmenu">
        <Flex justifyContent="space-between">
          <Box>
            <img
              style={{ maxHeight: 60 }}
              src={`https://xcdn.x0pa.ai/xfe/l/${xlogo}.png`}
              alt="Company Logo"
            />
          </Box>
          <Flex alignItems="center">
            {externalMenus.map((em) => {
              const { key, value, name, xtarget } = em;
              return (
                <Flex key={key} p={4}>
                  <Link color="blue.600" target={xtarget} href={value}>
                    {name}
                  </Link>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      </Container>
      <Outlet />
      <Container maxW="1200px" className="xcs-cs-footer">
        <Flex justifyContent="flex-end">
          {footerLinks.map((em) => {
            const { key, value, name, xtarget } = em;
            return (
              <Flex key={key} p={4}>
                <Link color="blue.600" target={xtarget} href={value}>
                  {name}
                </Link>
              </Flex>
            );
          })}
        </Flex>
      </Container>
    </Box>
  );
}
